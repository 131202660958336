<template>
  <header>
      <nav :class="'navbar navbar-expand navbar-light bg-white shadow-sm navs-sm fixed-top' + (isUserFrench ? ' page-french' : '')" aria-label="Second navbar example" style="z-index: 1030;" id="header">
        <div class="container-fluid padding0">
          <router-link :to="homePage()" class="navbar-brand">
            <img src="@/assets/images/logo.svg" alt="" />
          </router-link> 

          <div class="collapse navbar-collapse" data-html2canvas-ignore="true">
             <ul class="navbar-nav me-auto"></ul>
            <form v-if="isLogin()" class="form-inline-cs" >
              <a :class="(isUserFrench ? 'btn-border-blue btn-blue ' : 'btn-orange ') + 'text-center me-3'" data-bs-toggle="modal" data-bs-target="#modal-feedback"
                       style="cursor: pointer;">Feedback</a>
             
              <template v-if="isUserInter"> 
                <router-link to="/faq" class="btn-orange text-center me-3">{{ $t("FAQ") }}</router-link>               
              </template>

              <template v-if="isUserInter"> 
                <router-link to="/hta_roadmap/fr" target="_blank" class="btn-green text-center me-3" >{{ $t("HTA roadmap") }}</router-link>               
              </template>
              <router-link to="/time_to_access/gb" target="_blank" class="btn-green text-center me-3" >Time to access</router-link>               
              
              <template v-if="headerType === 'advance_result'"> 
                <router-link :to="{name: 'advance', params: {base64Search: $route.params.base64Search}}" class="btn-border-blue text-center me-3">{{ $t("Update my search") }}</router-link>               
              </template>

              <template v-if="headerType === 'advance'"> 
                <router-link :to="homePage()" class="btn-border-blue text-center me-3" >{{ $t("Simple search") }}</router-link>               
                <router-link to="/advance" class="btn-border-blue btn-blue text-center me-3" >{{ $t("Advanced search") }}</router-link>
              </template>
              
              <slot name="slot-icon"></slot>
              <div
                :class="'icon-user ' + (openMenu ? 'active' : '')"
                v-if="headerType !== 'login'"
                @click="handleClickMenu"
              ></div>
            </form>
             
          </div>
        </div>
      </nav>

      
      <div :class="'my-space ' + (openMenu ? 'open-bar' : '')">
        <div class="box-spacing">
        <ul>
          <li class="title-my-space">
            <p>
              <strong>{{ $t("My space") }}</strong>
            </p>
          </li>
          <li v-if="0">
            <a href="" target="_blank" rel="noopener">
              <img src="@/assets/images/prismheor.png" alt="" />
              <p>PrismHEOR</p>
            </a>
          </li>
          <li>
            <router-link to="/userkeyword" @click="handleClickMenuAndBoxSearchHiden"> 
              <img src="@/assets/images/icon-menu/ic-profil-historic-off.svg" alt="MySearches" />
              <div>
                {{ $t("headers_menu.MySearches") }}
                <span style="color: #e75112;">({{ count_keyword }})</span>
              </div>
            </router-link>
          </li>
          <li v-if="0">
            <router-link to="/my-document" @click="handleClickMenuAndBoxSearchHiden"> 
              <img src="@/assets/images/icon-menu/ic-profil-docs-off.svg" alt="My document" />
              <p>{{ $t("My document") }}</p>               
            </router-link>             
          </li>
          <li>
            <router-link to="/profile" @click="handleClickMenuAndBoxSearchHiden"> 
              <img src="@/assets/images/icon-menu/ic-profil-settings-off.svg" alt="My Profile" />
              <p>{{ $t("My Profile") }}</p>
            </router-link>
          </li>
          <li class="li-language" @click="openLang = !openLang">
            <div id="lang">
              <img src="@/assets/images/icon-menu/ic-profil-language-off.svg" alt="Language" />
              <p>{{ $t("Language") }}</p>
            </div>
            <div :class="'bg-lang ' + (openLang ? 'active' : '')"></div>
            <div :class="'list-lang ' + (openLang ? 'active' : '')">
              <div class="close-lang">
                <img src="@/assets/images/icon-menu/ic-close-orange.svg" alt="close-lang" />
              </div>
              <ul class="lang">
                <li
                  :class="$i18n.locale == lang ? 'lang-active' : ''"
                  v-for="(lang, key) in languages"
                  :key="key"
                  @click="setlang(lang)"
                >
                  {{ lang }}
                </li>
              </ul>
            </div>
          </li>
          <li v-if="0">
            <img src="@/assets/images/icon-menu/icon-feedback2.svg" alt="Help/Feedback" />
            <p>{{ $t("Help/Feedback") }}</p>
          </li>
          <li v-if="isAdmin && 0">
            <a href="/admin/" target="_blank" rel="noopener">
              <img src="@/assets/images/icon-menu/edit.svg" alt="Admin" />
              <p>Admin</p>
            </a>
          </li>
          <li data-bs-toggle="modal" data-bs-target="#modalConfirmLogout">
            <img src="@/assets/images/icon-menu/ic-profil-logout-off.svg" alt="Sign out" />
            <p>{{ $t("Sign out") }}</p>
          </li>
        </ul>
      </div>
    </div>
    <span
      :class="'bg-space' + (openMenu || openBoxSearch ? ' open-bar' : '')"
      @click="handleClickMenuAndBoxSearchHiden"
    ></span>

    <!-- Modal Déconnexion Logout-->
    <div class="modal fade" ref="confirmlogout" id="modalConfirmLogout" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
          <div class="modal-content">
              <div class="confirm-feedback">
                  <h2>{{ $t("Log out of my account.") }}</h2>
                  <span>{{ $t("You will disconnect. Are you sure?") }}</span>
                  <div class="box-area">
                      <div class="box-btn-menu">
                          <button class="cancel btn btn-border-orange"  data-bs-dismiss="modal"> {{ $t("Cancel") }}</button>
                          <button class="send btn btn-orange"  @click="logout()">  {{ $t("Confirm") }} </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>

    <ModalFeedback/>

  </header>
</template>
<script>
import ModalFeedback from '@/components/elements/modal_feedback.vue'
import { Modal } from 'bootstrap'
import { homePage, isAdmin, isUserFrench, isLogin, isUserInter } from "@/utils";
export default {
  name: "Header",
  components : {
    ModalFeedback
  },
  data() {
    return {
      openLang: false,
      languages: ["fr", "en", "de"],
      modal : null
    };
  },
  methods: {
    homePage,    
    isLogin,
    setlang(lang) {
      this.$i18n.locale = lang;
      localStorage.setItem("language", lang);
      window.location.reload();
    },
    handleClickMenu() {
      this.$store.dispatch("header/setOpenMenu", !this.openMenu);
    },
    handleClickMenuAndBoxSearchHiden() {
      this.$store.dispatch("header/setOpenMenu", false);
      this.$store.dispatch("header/setOpenBoxSearch", false);
    },     
    logout() {
      console.log('logout');
      this.handleClickMenuAndBoxSearchHiden();
      this.modal.hide();
      this.$store.dispatch("auth/logout");
    },
  },
  props: ["headerType"],
  computed: {
    isUserFrench,
    isUserInter,
    isAdmin,
    openMenu() {
      return this.$store.getters["header/openMenu"];
    },
    openBoxSearch() {
      return this.$store.getters["header/openBoxSearch"];
    },
    count_keyword() {
      return this.$store.getters["users/quanUserKeyword"];
    },    
    user_profile() {
      return this.$store.getters["users/user_profile"];
    },
  },
  mounted() { 
    this.modal = new Modal(document.getElementById('modalConfirmLogout'));
    if (localStorage.getItem("language") !== this.$i18n.locale) {
      window.location.reload();
    }
    if (isLogin() && this.count_keyword === '') {
      this.$store.dispatch("users/countUserKeyword");
    }
    if (isLogin() && !this.user_profile) {
      this.$store.dispatch("users/userData");
    }

    
  }
  
};
</script>

<style scoped>
/* 
.lang-active {
  color: #ff6300 !important;
}
.confirm-logout {
  padding: 45px 10px;
  text-align: center;
}
.confirm-logout h2 , .confirm-feeback h2 {
  font-weight: 600;
  font-size: 20px;
  color: #232426;
  margin: 0 0 8px;
}
.confirm-logout p ,.confirm-feeback p{
  color: #232426;
  font-size: 16px;
  margin: 0 0 30px;
}
.modal-content-logout, .modal-content-feedback {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  width: 640px;
}
.modal-dialog-logout {
  max-width: 640px !important;
}
.modal-logout {
  top: 25%;
  overflow-y: hidden !important;
}
.confirm-feeback {
  padding: 25px 10px;
  text-align: center;
}
.modal-feedback .confirm-feeback p {
  font-weight: bold;
  margin: 0 0 26px;
}
*/
#modal-feedback .confirm-feedback textarea {
    width: 100%;
    height: 228px;
    margin-bottom: 40px;
    padding: 10px;
    border: none;
    background-color: #efefef;
}  



</style>