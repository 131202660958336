<template>
    <section class="main-footer" v-if="!$route.query.zoom" :style="($route.path.search(/\badvance\b/) !== -1 || $route.path.includes('hta_roadmap')) ? 'position:relative' : ''">
        <div class="col-md-8 offset-md-3">
            <div class="txt-foot">
                <div class="box-foot">
                    <div class="me-3"><img src="@/assets/images/logo.svg" alt=""></div>
                    <div>
                        © {{$t('footer.PRISMACCESS IS A PLATFORM DEVELOPED BY PRIORITIS')}}<br>
                        {{$t('footer.ALL RIGHTS RESERVED')}} - <a href="https://www.prioritis.com/Privacy-Policy/">{{$t('footer.Privacy policy')}}</a> - <a href="https://www.prioritis.com/prioritis-terms-and-conditions">{{$t('footer.TERMS AND CONDITIONS')}}</a>
                    </div>
                </div>
            </div>
        </div>
    </section> 
</template>

<script>
export default {
    name: 'Footer',
    components: {
     
    }
    
}
</script>

<style scoped>
.main-footer a {
    color: #7d8693!important;
}
@media (max-width: 862px){

    .txt-foot {
        width: 100%;
    }

    .box-foot {
        text-align: center;
        padding: 0;
    }
}
</style>
